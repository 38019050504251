import AddYoutube from '../Views/Gallery/Components/AddYoutube';

export default function YoutubeVideoContainer(props) {
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const { url, setFieldValue } = props;
  const youtube = url?.match(regEx);

  return (
    <>
      {(url && youtube[1] && (
        <>
          <iframe
            // height="315"
            src={`https://www.youtube.com/embed/${youtube[1]}`}
            title="video"
            frameBorder="0"
            className="h-full w-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
            <AddYoutube edit={true} setField={setFieldValue} />
          </div>
        </>
      )) || (
        <div className="absolute inset-0 flex items-center justify-center">
          <AddYoutube setField={setFieldValue} />
        </div>
      )}
    </>
  );
}
