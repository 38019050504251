import { useEffect, useState } from 'react';
import CheckCircle from './Svg/CheckCircle';
import XCircle from './Svg/XCircle';

export default function Notification(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { error, message, extra, setExtraIsOpen } = props;
  const onClose = () => {
    setIsOpen(false);
    setExtraIsOpen(false);
  };
  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
      if (setExtraIsOpen) {
        setTimeout(() => {
          setExtraIsOpen(false);
        }, 500);
      }
    }, 1000);
  }, []);
  return (
    <div
      className={`fixed top-0 -mt-5 right-0 w-full transform transition-all duration-500 ${
        isOpen ? 'mt-5' : '-translate-y-full'
      }  `}
      style={{ zIndex: 100 }}
    >
      <div className="flex justify-center mx-auto ">
        <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg ">
          <div className="flex items-center">
            <div className="px-2">
              {error ? (
                <XCircle className="text-red-500 w-5 h-5" />
              ) : (
                <CheckCircle className="text-green-500 w-5 h-5" />
              )}
            </div>
            <div className="ml-2 mr-6">
              <span className="font-semibold text-black">{message ? message : 'Successfully Saved!'}</span>
              {/* <span className='block text-gray-500'>
              {extra ? extra : 'Anyone with a link can now view this file'}
            </span> */}
            </div>
            {/* <div>
              <button
                type='button'
                onClick={onClose}
                className='text-xs text-gray-500 hover:bg-gray-100 py-1 px-2 transition-all focus:outline-none rounded-full'
              >
                X
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
