import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import Loading from '../../SharedComponents/Loading';
import { getCategories, getLanguages } from './ApiCategories';
import AddCategory from './AddCategory';
import DeleteCategory from './DeleteCategory';
import EditCategory from './EditCategory';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import LanguageList from '../../SharedComponents/LanguageList';
import TogglePrimary from './TogglePrimary';

export default function Categories() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const { t, i18n } = useTranslation();
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, isLoading, isError } = useQuery(['categories', initialState], getCategories, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/categories" name={t('Categories')} />
        <div>
          <AddCategory />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Categories List')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex justify-between relative items-center px-6 py-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3  ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }   uppercase  ${e.className}`}
                            >
                              {t(e.title)}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data &&
                        data.data &&
                        data?.data?.map((category) => {
                          return (
                            <tr key={category.id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {category.pgPageCategoryLngs.map((x) => {
                                  return <span key={x.id}>{languageId === x.languageId && <> {x.name} </>}</span>;
                                })}
                                {/* <div className=' '>
                                {faq.faqLngs[0]?.title}
                              </div> */}
                              </td>

                              <td
                                className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                }`}
                              >
                                {/* <EditRole id={user.id} name={user.name} /> */}
                                {/* <DeleteRole id={user.id} /> */}
                                <TogglePrimary category={category} />
                                <EditCategory category={category} />
                                <DeleteCategory id={category.id} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
