import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import Home from '../../SharedComponents/Svg/Home';
import TablePagination from '../../SharedComponents/TablePagination';
import { getLanguages, getGalleryAlbum } from './ApiGallery';
import AddGalleyImage from './Components/AddGalleyImage';
import GalleryBlock from './Components/GalleryBlock';
import SelectMultipleImages from './Components/SelectMultipleImages';
import SelectMultipleVideos from './Components/SelectMultipleVideos';

export default function Gallery(props) {
  const [languageId, setLanguageId] = useState(1);
  const { albumId } = useParams();
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  let initialState = {
    albumId: albumId,
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      isPaging: true,
      langId: 0,
    },
  };
  const { data, isLoading, isError } = useQuery(['galleryAlbum', initialState], getGalleryAlbum, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    console.log('initiationgpagination', data);
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  if (isLoading) return <Loading />;
  if (isError) return 'error';
  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/galleryalbums" name={t('Galley Albums')} />
        <div className="flex space-x-4">
          <SelectMultipleImages />
          <SelectMultipleVideos />

          <AddGalleyImage />
        </div>
      </header>
      <div className="bg-white px-4 py-4">
        <div className="flex justify-between relative items-center mb-4">
          <div className="flex transform  relative z-10">
            <span className="font-bold text-xl pb-3">{t('Gallery Albums List')}</span>
          </div>
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 ">
          {data.data.map((albumItem) => {
            return <GalleryBlock key={albumItem.id} languageId={languageId} albumItem={albumItem} />;
          })}
        </div>
      </div>
      <TablePagination
        data={data}
        paginationCount={paginationCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </>
  );
}
